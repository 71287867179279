<template>
  <div class="helpInfo">
    <VueMarkdown :source="this.info.content"></VueMarkdown>
  </div>
</template>
<script>
import { GetFAQContentById } from "@/request/api";
import VueMarkdown from "vue-markdown";
export default {
  components: {
    VueMarkdown, // 注入组件
  },
  name: "App",
  data() {
    return {
      info: {},
    };
  },
  methods: {},
  async created() {
    let id = this.$route.query.id;
    console.log(id);
    this.info = await GetFAQContentById(id).then((res) => {
      if (res.code == 200) {
        return res.result;
      }
    });
    console.log(this.info);
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.helpInfo {
  text-align: left;
}
</style>
